<template>
    <!-- 通道效果回传上传文件 -->
    <div id="channel_backhaul_upload_id">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_title">通道效果回传上传文件</div>
        </el-row>

        <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                <el-form :model="form_data" label-width="120px" style="margin-bottom: 12px;">

                    <el-form-item label="导入文件">
                        <!-- show-file-list:是否显示已上传文件列表  默认值 true
                        multiple:是否支持多选文件 
                        data:上传时附带的额外参数
                        auto-upload	是否在选取文件后立即进行上传
                        with-credentials 是否支持发送 cookie 凭证信息  true:支持
                        -->
                        <el-upload ref="uploadFile" style="display: inline-block;"
                                :action="upload_url" :multiple="false" :limit="1" :auto-upload="false"
                                :with-credentials="true"
                                accept=".txt"

                                :on-success="uploadResponseResult"
                                :on-error="uploadResponseResultFail"

                                :file-list="fileList"
                                
                                :on-change="uploadChange"
                                :on-remove="uploadChange"
                                :before-upload="beforeAvatarUpload"
                                >
                            <el-button size="mini" type="primary" plain @click="upload()">选取文件</el-button>
                            <div slot="tip" class="el-upload__tip">
                                txt格式文件，ANSI编码，且上传文件大小限制最高4M<br/>
                                文件格式（每列参数以“TAB(制表符)”分割）：<br/>
                                手机号	签名	发送时间（yyyy-MM-dd HH:mm:ss） 类型<br/>
                                导入文件示例：<br/>
                                <img src="@/assets/img/xw_import_file.png" style="width:100%"/>
                            </div>
                        </el-upload>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="onSubmit()" size="mini" :disabled="isAvailable == 1">保存</el-button>
                    </el-form-item>
                </el-form>

            </el-row>

        </el-row>
    </div>
</template>
<script>
import API from '../../../api/api';
import {
    getItem
} from '../../../api/tool.js';

export default {

    data(){
        return{
            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            isAvailable: 0,//保存按钮是否可用 0：可用 1：不可用

            form_data:{},
            
            upload_url:"",//上传url
            fileList:[],//文件列表
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
        },
         //数据清除
        dataClear(){
            this.fileList = [];
        },
        //上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。
        beforeAvatarUpload(file) {
            const imgType = file.name.endsWith('.txt');
            const isLt4M = file.size / 1024 / 1024 < 4;

            if (!imgType) {
                this.$message.error('上传文件只能是 txt 格式!');
            }
            if (!isLt4M) {
                this.$message.error('上传文件大小不能超过 4MB!');
            }
            if(imgType && isLt4M){
                return new Promise((resolve, reject) => {
                    // 拼接上传url
                    // 此处动态配置action URL
                    this.upload_url = API.getInterfaceUrl()+"/ChannelBackhaulServlet?param=upload_file&session="+getItem("qxt_session")
                    +"&callback="+( new Date().getTime()+ "_" + Math.ceil(Math.random() * 1000) );
                    // console.log(this.upload_url);
                    // dom上传地址更新完成后，触发上传
                    this.$nextTick(() => resolve());
                });
            }else{
                return imgType && isLt4M;
            }
        },
         //上传 - 文件上传或从列表中移除时触发 
        uploadChange(file, fileList){
            // console.log("文件上传或从列表中移除时触发 "+JSON.stringify(fileList));
            this.fileList = fileList;
        },

        //上传 按钮触发方法
        upload(){
            console.log("上传 按钮触发方法");
            if(this.$refs.uploadFile){
                this.$refs.uploadFile.clearFiles(); //上传成功之后清除历史记录
            }
            // this.upload_param:{},//上传带的额外参数
        },
        //上传成功响应结果
        uploadResponseResult(res, file, fileList) {
            //response这个
            // console.log("上传响应结果res:"+JSON.stringify(res));
            if(this.$refs.uploadFile){
                this.$refs.uploadFile.clearFiles(); //上传成功之后清除历史记录
            }
            if(res){
                // res = JSON.parse(res);
                if(res.recode == 0){
                    var duration = 2000;
                    if(API.isEmtry(res.text)){
                        this.isAvailable = 0;
                        res.text = "恭喜您，提交成功";
                        API.router_to("/channel_backhaul_upload_list");
                    }else{
                        duration = 10000;
                    }
                    // this.$message.error(res.text,{duration:duration});
                    this.$message({
                        showClose: true,
                        message: res.text,
                        duration:duration,
                        type: 'success'
                    });
                }else{
                    this.$message.error(res.text);
                }
            }else{
                 this.$message.error("文件上传失败，请稍后再试哦");
            }    
        },
        
        //上传失败响应结果
        uploadResponseResultFail(err, file, fileList){
            console.log("上传失败响应结果");
            this.$message.error("文件上传失败了，请稍后再试哦");
        },

        //提交
        onSubmit(){
            // console.log("数据提交 file_num:"+this.fileList.length+"\t"+JSON.stringify(this.fileList));
            if(this.fileList.length == 0){
                this.$message.error("请先选择文件");
                return ;
            }else{
                this.isAvailable = 1;
                this.$refs.uploadFile.submit();
            }
        },
        //返回
        goBack() {
            API.router_to("/channel_backhaul_upload_list");
        },

    }
};
</script>

<style scoped>
/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}
</style>